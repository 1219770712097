import '../../styles/Loading.css'
const Loading = ({isVisible}) => {
	return (
		<div className={'modal ' + (isVisible ? 'd-block' : '')} tabindex="-1">
			<div className="modal-dialog modal-dialog-centered w-100 text-center">
				<div className="modal-content modal-content bg-transparent border-0">
					<div className="modal-body">
						<div class="loader-container">
							<div class="loader"></div>
							<div class="loader-text">Loading...</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Loading