import React from 'react'

import FeBookingForm from './FeBookingForm'

class App extends React.Component
{
	render() {
		return(
			<FeBookingForm />
		)
	}
}

export default App